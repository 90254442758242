import Avatar from "../Avatar/Avatar";
import "./feed.css";

const Feed = () => {
    return (
        <div className="feed">
            {/* date */}
            <div className="feed_date">
                <Avatar />
                <p>07/27/2024</p>
            </div>
            {/* img */}
            <div className="feed_img">
                <img src="./assets/img/aiacbanner.jpg" alt="feed_image" />
            </div>
            {/* content */}
            <div className="feed_content">
                <p>
                    2nd year computer science student at AIAC
                </p>
            </div>
        </div>
    );
};

export default Feed;