import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthLayout from './Layouts/AuthLayout/AuthLayout';
import ProfileLayout from './Layouts/ProfileLayout/ProfileLayout';
import ResetLayout from './Layouts/ResetLayout/ResetLayout';
import ActivateLayout from './Layouts/ActivateLayout/ActivateLayout';
import { AuthContext } from './context/AuthContext';
import { useContext, useEffect } from 'react';
import axios from "axios";



function App() {
  const { dispatch, token, isLoggedIn } = useContext(AuthContext);

  // get ac token
  useEffect(() => {
    const _appSignging = localStorage.getItem("_appSignging");
    if (_appSignging) {
      const getToken = async () => {
        const res = await axios.post("/api/auth/access", null);
        dispatch({ type: "GET_TOKEN", payload: res.data.ac_token });
      };
      getToken();
    }
  }, [dispatch, isLoggedIn]);

  // get user data
  useEffect(() => {
    if (token) {
      const getUser = async () => {
        dispatch({ type: "SIGNING" });
        const res = await axios.get("/api/auth/user", {
          headers: { Authorization: token },
        });
        dispatch({ type: "GET_USER", payload: res.data });
      };
      getUser();
    }
  }, [dispatch, token]);


  return (
    <Router>
      <Routes>
        <Route path='/' exact Component={isLoggedIn ? ProfileLayout : AuthLayout} />
        <Route path='/reset-password/:token' element={<ResetLayout />} />
        <Route path='/api/auth/activate/:activation_token' exact Component={ActivateLayout} />
      </Routes>
    </Router>
  );
}

export default App;
